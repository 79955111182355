import React from 'react';
import {Link, navigate} from 'gatsby';

import {useI18} from '../../hooks/i18n';
import styled from "styled-components";

interface ILocaleToggle {
    currentLocale: string;
    pathname: string;
    className?: string;
}

const LocaleToggle: React.FC<ILocaleToggle> = ({
                                                   currentLocale,
                                                   pathname,
                                                   className,
                                               }) => {
    const {changePathnameLocale, locales} = useI18({
        currentLocale,
    });
    if (locales.length < 2) return null;
    const options = locales.map((locale) => ({
        value: locale.code,
        label: locale.code,
    }));

    const onLocaleChange = async ({value}: { value: string }) => {
        await navigate(changePathnameLocale(value, pathname));
    };

    return (
        <div className={className}>
            {options.map((lang) => {
                return <a key={lang.value}
                          className={"SiteLink"}
                          title={lang.value?.split("-")[0].toUpperCase()}
                          onClick={() => onLocaleChange(lang)} > {lang.value?.split("-")[0].toUpperCase()} </a>
            })}
        </div>
    );
};



const SiteLink = styled(Link)`
                    :hover {
                        box-shadow: inset 0 -2px 0 0 white; 
                    }
                    `;
export default LocaleToggle;
