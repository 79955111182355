export const sizes = {
    maxContainerWidth: '1160px',
    headerDesktopHeight: '120px',
};

export const colors = {
    surface: '#FFFFFF',
    heroOverlay: 'rgba(20, 20, 20, 0.3)',
    background: 'rgba(0, 0, 0, 1)',
    backgroundOverlay: 'rgba(20, 20, 20, 0.7)',
    dark: {
        border: 'rgba(162,162,162,0.5)',
    },
    text: '#FFFFFF',

};

export const fonts = {
    lato: 'Lato, sans-serif',
    merriweather: 'Merriweather, sans-serif',
    serif: 'georgia, serif',
    sansSerif:
        'Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
    code:
        '"SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono", "Liberation Mono", Menlo, Courier, monospace;',
};
