import styled from 'styled-components';
import {sizes} from './theme';

export const BaseContainer = styled.div`
  max-width: ${sizes.maxContainerWidth};
  margin: 0 auto;
  padding: 2rem 1rem;
  
  @media screen and (max-height: 800px) {
       padding: 1rem 0rem;
  }
`;


export const HeroContainer = styled.div`
  min-width: 300px;
  margin: 1rem;
  padding: 1rem 1rem;
  
`;
