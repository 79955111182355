import React, {useState} from 'react';
import styled from 'styled-components';
import {BaseContainer} from '../../styles/BaseComponents';
import {colors, fonts} from '../../styles/theme';
import {Link} from "gatsby";
import {IHeaderProps} from "../Header";
import {useI18} from "../../hooks/i18n";
import GatsbyImage, {FixedObject} from "gatsby-image";
import LocaleToggle from "../LocaleToggle";


const Footer: React.FC<IHeaderProps> = ({navigation, locale, pathname}) => {
        const {createLocalizedPathname} = useI18({
            currentLocale: locale,
        });


        return (
            <StyledFooter>
                <hr/>
                <StyledBaseContainer>
                    <SiteContainer>


                        {navigation?.sideNavigation?.map((navItem) => (


                            <SiteLink className="option"
                                      to={createLocalizedPathname(navItem?.path as string)}

                                      key={navItem?.path as string}>
                                {navItem?.name}
                            </SiteLink>

                        ))}


                    </SiteContainer>
                </StyledBaseContainer>

                <StyledBaseContainer>


                    <Container>
                        <HomeLink
                            to={createLocalizedPathname(navigation?.homePage?.path as string)}>
                            <GatsbyImage
                                fixed={navigation?.logo?.fixed as FixedObject}
                                loading="lazy"
                                fadeIn={true}
                                durationFadeIn={500}
                            />

                        </HomeLink>

                        <div>

                            © {new Date().getFullYear()}
                            {` `}
                            <a href="https://www.houblonbastien.com">houblon bastien</a>
                        </div>
                    </Container>

                    <Container>

                        <LocaleToggle currentLocale={locale} pathname={pathname} className={"lang"}/>

                        <a href="https://www.instagram.com/houblonbastien/" target={"_new"}
                           className={"social"}
                           title={"Instagram"}> Instagram </a>

                        <a href="https://www.facebook.com/houblonbastien/"
                           className={"social"}
                           target={"_new"} title={"Facebook"}> Facebook </a>

                        <a href="https://www.linkedin.com/company/houblon-bastien/"
                           className={"social"}
                           target={"_new"} title={"LinkedIn"}> LinkedIn </a>



                    </Container>

                </StyledBaseContainer>

            </StyledFooter>)
    }

;

const StyledFooter = styled.footer`

background: ${colors.background};
font-family: ${fonts.merriweather};
color: ${colors.text};
a {
    font-family: ${fonts.merriweather};
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.text};
    margin-right: 20px;
}

.social :hover{
    box-shadow: inset 0 -2px 0 0 white; 

}

hr {
 background-color: white
}
padding-top: 2rem;
padding-bottom: 2rem;

`;


const SiteContainer = styled.footer`
 display: flex;
 flex-direction: column;
 
align-items: center;
font-size: 14px;
a {
    
    padding: 1em;
}

 @media (min-width: 800px) {

     
    flex-direction: row;
  }
`;


const Container = styled(BaseContainer)`

    display: flex;
    justify-content: space-between;
 
 
 margin-top: 1rem;
align-items: center;

`;

const StyledBaseContainer = styled(BaseContainer)`
    padding-top: 2rem;

display: flex;
font-size: 12px;
justify-content: space-around;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const HomeLink = styled(Link)`
                    display: flex;
                    align-items: center;
                    span {
                    margin-left: 1rem;
                    }
                    `;

const SiteLink = styled(Link)`
                    :hover {
                        box-shadow: inset 0 -2px 0 0 white; 
                    }
                    `;


export default Footer;
