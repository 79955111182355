import React from 'react';
import styled from 'styled-components';
import Header, {IHeaderProps} from '../Header';
import Seo, {ISeoProps} from '../Seo';
import Footer from '../Footer';
import {sizes} from '../../styles/theme';
import GlobalStyles from '../../styles/GlobalStyles';
import CookieConsent from "react-cookie-consent";

interface ILayoutProps extends Omit<IHeaderProps, 'locale'> {
    seo: ISeoProps;
}

const Layout: React.FC<ILayoutProps> = ({
                                            children,
                                            seo: {title, description, locale, image},
                                            navigation,
                                            pathname,
                                        }) => (
    <>
        <CookieConsent
            buttonText={locale==='en-US'?"Accept":"Accepter"}
        >{locale==='en-US'?"This website uses cookies to enhance the user experience.":"Ce site web utilise des cookies pour améliorer l'expérience utilisateur."}</CookieConsent>

        <GlobalStyles/>
        <Seo
            description={description}
            locale={locale}
            title={title}
            image={image}
        />
        <Header
            navigation={navigation}
            locale={locale as string}
            pathname={pathname as string}
        />
        <Main>
            <StyledMain>{children}</StyledMain>
        </Main>
        <Footer
            navigation={navigation}
            locale={locale as string}
            pathname={pathname as string}
        />
    </>
);

const StyledMain = styled.main`
  min-height: calc(100vh - ${sizes.headerDesktopHeight});
  background: black
`;

const Main = styled.main`

`;



export default Layout;
