import {Link} from 'gatsby';
import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import GatsbyImage, {FixedObject, FluidObject} from 'gatsby-image';
import {SiteNavigationFragment} from '../../../gatsby-graphql';
import {BaseContainer} from '../../styles/BaseComponents';
import {useI18} from '../../hooks/i18n';
import {colors, fonts, sizes} from '../../styles/theme';
import LocaleToggle from '../LocaleToggle';
import "./Header.css";


export interface IHeaderProps {
    navigation: SiteNavigationFragment['navigation'];
    locale: string;
    pathname: string;
}

function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener("scroll", updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
        }
    }, [scrollDirection]);

    return scrollDirection;
};
const Header: React.FC<IHeaderProps> = ({navigation, locale, pathname}) => {
    const {createLocalizedPathname} = useI18({
        currentLocale: locale,
    });
    const scrollDirection = useScrollDirection();

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <div className={`header  ${scrollDirection === "down" ? "down" : ""}`} >
            <div className="logo-nav">

                <HomeLink
                    to={createLocalizedPathname(navigation?.homePage?.path as string)}
                >
                    <GatsbyImage
                        fixed={navigation?.logo?.fixed as FixedObject}
                        loading="lazy"
                        fadeIn={true}
                        durationFadeIn={500}
                    />

                </HomeLink>

                <ul className={click ? "nav-options active" : "nav-options"}>
                    {navigation?.sideNavigation?.map((navItem) => (

                        <li key={navItem?.path as string}>
                            <SiteLink className="option"
                                      to={createLocalizedPathname(navItem?.path as string)}
                                      onClick={closeMobileMenu}
                                      key={navItem?.path as string}>
                                {navItem?.name}
                            </SiteLink>
                        </li>

                    ))}

                </ul>

            </div>

            <StyledLocaleToggle currentLocale={locale} pathname={pathname} className={"lang"}/>


            <div className="mobile-menu" onClick={handleClick}>
                {click ? (
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAA10lEQVRIie2W3Q2CUAyFiztIcAe3YG4mwCcxYQeX+HxpIiEX6JEmJspJeKE9fLfl/pkd+ksBNXDzp97h78N+4AzceWsELgL0M7+PdK4hMnKvdCj4+3nuKVjI1cy6NbjHOs/VVWjVVMW2BTxNOjwNqsDToRP40mTB36/F5GWoVJ5b6Q54HlSAS9DoOo6qSv2a2Orw9poFzYPzjeW0UekINIEcrfIIVMiNwRVoKpzyeQwb/431+fDxefwws7aqqudSgsdaz9VVaJu0I+3ye9t6lMtaov/Qb+gFR+sH5aRmFKEAAAAASUVORK5CYII="/>

                ) : (
                    <img className={"nav-icon"}
                         src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAABK0lEQVRoge3azU3DQBDF8f8sV+ggBqWA1EEDKSaVAEkNXAGJLqABlA9RQHK19XKwLXLejTQ2nt99te9ZK81KXuhImktaS9pLqjU8tdpsL5LmfW7rwj8Cr8At43AClmb2aV2bb8YTvncCFglYMb7wAHfAyiTtgZl3mkw7k1QDN95JMjUmSd4pSiTvAKWigLco4C0KeCsp8AbMrBBQAR+5IUoGWWVmh9yNL0mqgF3O2kkfoY2k4ktg9/U3uevjLuQtCniLOZC78aWYA5liDlzDpI/QIEy6QMyBXsyBTDEHrmHSR2gQooC3KODtXxRovEMUaBLw652iwCFRcBMcgPf+qcEX7Z/vMTkCi2RmP8CS9u3BWBxpH3v8XcElPUh6lrTVcJ/bbCU9Sbrvc58B/4jEyYPVux8AAAAASUVORK5CYII="/>
                )}
            </div>
        </div>
    );

};

const StyledHeader = styled.div`
            position: sticky;
            top: 0px;
            z-index: 1;
            background: ${colors.backgroundOverlay};
            a {
            text-decoration: none;
            color: ${colors.text};
            font-family: ${fonts.merriweather};
        }
            img {
            margin-bottom: 0;
        }

            `;

const HomeLink = styled(Link)`
            display: flex;
            align-items: center;
            span {
            margin-left: 1rem;
        }
            `;

const SiteLink = styled(Link)`
            :hover {
            box-shadow: inset 0 -2px 0 0 white;
        }

            `
const StyledBaseContainer = styled(BaseContainer)`
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: ${sizes.headerDesktopHeight};
            `;

const SideNavigationContainer = styled.div`
            display: flex;
            align-items: center;
            a {
            margin-left: 1rem;
            &.active {
            font-weight: bold;
        }
        }

            `;

const StyledLocaleToggle = styled(LocaleToggle)`
            margin-left: 1rem;
            margin-right: 1rem;
            `;

export default Header;
